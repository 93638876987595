import CustomModal from 'components/modals/Modal';
import gtagEvent from 'libs/gtagEvent';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    setClaimDisclaimerModalVisible,
    setOnlineClaimServicesVisible,
} from 'store';
import { StoreType } from 'store/types';
import { UiType } from 'types/ui';

interface Props {
    align: string;
}

export const ClaimDisclaimerModal: React.FC<Props> = ({ align }) => {
    const uiState = useSelector<StoreType, UiType>(
        (state) => state.ui,
    );
    const { claimDisclaimerModalVisible } = uiState;
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <CustomModal
            handleClose={() => {
                gtagEvent({
                    screen: 'newclaim_disclaimer',
                    action: 'cancel',
                    label: 'Cancelled or closed modal',
                    type: 'modal',
                });
                dispatch(setClaimDisclaimerModalVisible(false));
            }}
            align={align}
            show={claimDisclaimerModalVisible}
            className={'claim-disclaimer-modal'}>
            <>
                <div className="inner">
                    <div className="text-center">
                        <div className="text-left">
                            <div>
                                <h1 className={'text-primary text-uppercase'}>
                                    Make a new Claim
                                </h1>

                                <p>
                                    The Member Portal offers online claiming for
                                    all general claims that meet the following
                                    requirements:
                                </p>

                                <ul>
                                    <li className={'mb-3'}>
                                        The service provided was within the last
                                        two (2) years
                                    </li>
                                    <li className={'mb-3'}>
                                        The service falls under one of the
                                        service types listed.{' '}
                                        <Button
                                            onClick={() => {
                                                dispatch(
                                                    setOnlineClaimServicesVisible(
                                                        true,
                                                    ),
                                                );
                                            }}
                                            variant="font-weight-bold text-primary-dark-1 p-0 m-0">
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                }}>
                                                Click here to check the list.
                                            </span>
                                        </Button>
                                    </li>
                                    <li className={'mb-3'}>
                                        If your claim does not meet these
                                        requirements please try one of our other{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://tuh.com.au/information/using-your-extras/make-claim-extras">
                                            claiming options
                                        </a>{' '}
                                        or download our Smart Claiming App with
                                        your smartphone and submit your claim
                                        with just a few clicks.
                                    </li>
                                </ul>

                                <p>
                                    Union Health requires receipts for claims submitted using this online claiming function. 
                                    Online claims will be subject to audits. Please ensure you retain all receipts 
                                    for a period of (12) twelve months after submission.
                                </p>
                            </div>
                            <div className="text-center pt-3">
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'newclaim_disclaimer',
                                            action: 'continue',
                                            label: 'Viewed and agreed to HRA modal disclaimer',
                                            type: 'modal',
                                        });
                                        dispatch(
                                            setClaimDisclaimerModalVisible(
                                                false,
                                            ),
                                        );
                                        history.push('/member/claim/start');
                                    }}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </CustomModal>
    );
};

export default ClaimDisclaimerModal;
